import React, { useEffect, useState, useRef } from "react";
import { db, auth } from "./firebase";
import { DataGrid } from "@material-ui/data-grid";
import { Box, Button, Input } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import clsx from "clsx";
import { useHistory } from "react-router-dom";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    padding: "1% 8%",
    maxHeight: "70%",
    overflow: "scroll",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  root: {
    "& .cell.negative": {
      backgroundColor: "#45e21299",
      fontWeight: "600",
      textAlign: "center",
    },
    "& .cell.positive": {
      backgroundColor: "#f700007a",
      fontWeight: "600",
      textAlign: "center",
    },
    "& .cell.moderate": {
      backgroundColor: "#f9f959",
      fontWeight: "600",
      textAlign: "center",
    },
  },
}));

export default function DataTable() {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [info, setInfo] = useState({});

  const history = useHistory(); //this is used to navigate between pages

  const docID = history.location.state.docID;
  const docInfo = history.location.state.info;

  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 150,
      editable: true,
    },
    {
      field: "time",
      headerName: "Time",
      width: 150,
      editable: true,
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      editable: true,
    },
    { field: "id", headerName: "Ref. ID", width: 150 },
    { field: "phone", headerName: "Phone #", width: 150 },
    { field: "email", headerName: "Email", width: 250 },
    {
      field: "vaccine",
      headerName: "# of Vaccines",
      width: 200,
      cellClassName: (params) =>
        clsx("cell", {
          negative: params.value == "2",
          positive: params.value == "0",
          moderate: params.value == "1",
        }),
    },
    {
      field: "atRisk",
      headerName: "Risk",
      width: 105,
      cellClassName: (params) =>
        clsx("cell", {
          positive: params.value == "Yes",
          negative: params.value == "No",
        }),
    },
    {
      field: "info",
      headerName: "Additional Info.",
      disableClickEventBubbling: true,
      width: 200,

      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="default"
            size="small"
            style={{ marginLeft: 25 }}
            onClick={() => openModal(params.value)}
          >
            View
          </Button>
        </strong>
      ),
    },
  ];

  useEffect(() => {
    db.collection("companies_test")
      .doc(docID)
      .collection("submissions")
      .onSnapshot((snapshot) => {
        setRows(
          snapshot.docs.map((doc) => ({
            id: doc.data().referenceID,
            name: doc.data().Name,
            atRisk:
              doc.data().Symptoms[0] == "none" &&
              doc.data().Waiting == "no" &&
              (doc.data().Vaccinated == "1" || doc.data().Vaccinated == "2") &&
              doc.data().Isolating == "no" &&
              doc.data().Travelled == "no" &&
              doc.data().CloseContact == "no"
                ? "No"
                : "Yes",
            date: doc.data().Date,
            time: doc.data().Time,
            phone: doc.data().Phone,
            email: doc.data().Email,
            vaccine: doc.data().Vaccinated,
            info: {
              id: doc.data().referenceID,
              name: doc.data().Name,
              birthdate: doc.data().Birthdate,
              address: doc.data().Address,
              phone: doc.data().Phone,
              email: doc.data().Email,
              symptoms: doc.data().Symptoms,
              waiting: doc.data().Waiting,
              vaccine: doc.data().Vaccinated,
              isolated: doc.data().Isolating,
              travelled: doc.data().Travelled,
              closecontact: doc.data().CloseContact,
              signature: doc.data().signatureUrl,
            },
          }))
        );
      });
  }, []);

  function openModal(info) {
    setInfo(info);
    setOpen(true);
  }

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          history.push(`/`);
          auth.signOut();
        }}
      >
        Logout
      </Button>
      <div className="app__header">
        <img id="app__headerimage" src={docInfo.logo} alt="" />
        <h2>{docInfo.name} Dashboard</h2>
        <Button
          onClick={() => {
            history.push(`/form/${docID}`);
          }}
          variant="contained"
        >
          Open Form
        </Button>
      </div>
      {/* Additional Info. Modal */}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Fade in={open}>
          <div style={modalStyle} className={classes.paper}>
            <form className="app__signup">
              <center>
                <h3>{info.name}</h3>
                <Box padding={0.5} />

                <p id="text">
                  <b>Reference ID:</b> {info.id}
                </p>
                <p>
                  <b>Birthdate:</b> {info.birthdate}
                </p>
                <p>
                  <b>Address:</b> {info.address}
                </p>
                <p>
                  <b>Phone:</b> {info.phone}
                </p>
                <p>
                  <b>Email Address:</b> {info.email}
                </p>
                <Box padding={0.5} />

                <h4>Screening Information</h4>
                <p>
                  <b>Symptoms: </b>
                  {info.symptoms}
                </p>
                <p>
                  <b># of Vaccinations: </b>
                  {info.vaccine}
                </p>

                <p>
                  <b>Waiting for Results? </b>
                  {info.waiting}
                </p>
                <p>
                  <b>Should be self-isolating? </b>
                  {info.isolated}
                </p>
                <p>
                  <b>Travelled outside Canada (last 14 days)? </b>
                  {info.travelled}
                </p>
                <p>
                  <b>Close contact of someone with COVID? </b>
                  {info.closecontact}
                </p>
                <img id="signature" src={info.signature} />
                <Box padding={1} />
                <Button onClick={() => setOpen(false)}>Close</Button>
              </center>
            </form>
          </div>
        </Fade>
      </Modal>
      {/* Table */}
      <div style={{ height: 500, width: "100%" }} className={classes.root}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
        />
      </div>
    </>
  );
}
