import "./App.css";
import React, { useRef, useState, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import {
  Button,
  TextField,
  Checkbox,
  Radio,
  RadioGroup,
  Tooltip,
} from "@material-ui/core";
import firebase from "firebase";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

var data;
var referenceID;

function Form(props) {
  const inputRef = useRef(null); //this is used to reference the SignatureCanvas props
  const history = useHistory(); //this is used to navigate between pages
  const match = useRouteMatch();
  var { id } = useParams();
  const [docInfo, setDocInfo] = useState();
  const [address, setAddress] = useState();

  useEffect(() => {
    //getting company info
    firebase
      .firestore()
      .collection("companies_test")
      .doc(id)
      .get()
      .then((doc) => {
        setDocInfo(doc.data());
      })
      .catch(function (error) {
        console.error("Error submitting form!");
      });
    console.log(docInfo);
    // return () => {
    //   cleanup;
    // };}
  }, []);

  var today = new Date();
  var year = today.getFullYear();
  var month = today.getUTCMonth();
  var day = today.getDate();

  if (docInfo) {
    return (
      <div className="form">
        <div className="header">
          <img className="card__header--image" src={docInfo.logo} alt="" />
          <h2>{docInfo.name}® - Screening Form</h2>
          <h4>
            {docInfo.address} ({docInfo.phone})
          </h4>
        </div>
        {/* Instructions Card */}
        <div className="card">
          <h3>
            Please complete the Client Health Screening to help us reduce the
            spread of COVID-19 and to keep our people, our clients and our
            community safe.
          </h3>{" "}
          <p>This waiver form is only valid for 6 hours from completion.</p>
        </div>
        <form id="form" onSubmit={upload}>
          {/* Personal Details Card */}
          <div className="card">
            <h3>Personal Details</h3>
            {/* Name */}
            <div className="form__row">
              <TextField
                id="name"
                label="Name"
                variant="outlined"
                type="name"
                fullWidth
                required
              />
            </div>
            {/* Phone */}
            <div className="form__row">
              <TextField
                id="phone"
                type="tel"
                label="Phone Number"
                variant="outlined"
                fullWidth
                required
              />
            </div>
            {/* Email */}
            <div className="form__row">
              <TextField
                id="email"
                label="Email Address"
                type="email"
                variant="outlined"
                fullWidth
                required
              />
            </div>
            {/* Address */}
            <div className="form__row">
              <GooglePlacesAutocomplete
                apiKey="AIzaSyAfyVneLAg4iNFH0cmQtfjyZIpCPYSC7aQ"
                selectProps={{
                  address,
                  onChange: setAddress,
                  placeholder: "Enter Your Address...",
                  styles: {
                    input: (provided) => ({
                      ...provided,
                      padding: "12px 0",
                    }),
                  },
                }}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ["ca"],
                  },
                }}
              />
            </div>
            {/* Birthdate */}
            <div className="form__row">
              <label htmlFor="birthdate" className="form__row--header">
                Birthdate:{" "}
              </label>
              <TextField
                id="birthdate"
                type="date"
                variant="outlined"
                fullWidth
                required
              />
            </div>
          </div>
          {/* Screening Card */}
          <div className="card">
            <h3>Screening</h3>
            {/* Symptoms */}
            <div className="form__row">
              <div>
                <label htmlFor="symptoms" className="form__row--header">
                  Are you experiencing any of the following symptoms?
                </label>
              </div>

              <Checkbox
                type="checkbox"
                id="cough"
                name="symptoms"
                value="cough"
              />
              <Tooltip
                title="Continuous, more than usual, making a whistling noise when breathing (not related to asthma, post-infectious reactive airways, COPD, or other known causes or conditions you already have)"
                placement="right-end"
              >
                <label htmlFor="cough">Cough</label>
              </Tooltip>
              {/* <p>
                Continuous, more than usual, making a whistling noise when
                breathing (not related to asthma, post-infectious reactive
                airways, COPD, or other known causes or conditions you already
                have)
              </p> */}
              <br />
              <Checkbox
                type="checkbox"
                id="fever"
                name="symptoms"
                value="fever"
              />
              <label htmlFor="fever">Fever</label>
              <br />
              <Checkbox
                type="checkbox"
                id="shortness_of_breath"
                name="symptoms"
                value="shortness of breath"
              />
              <label htmlFor="shortness_of_breath">Shortness of Breath</label>
              <br />
              <Checkbox
                type="checkbox"
                id="sore_throat"
                name="symptoms"
                value="sore throat"
              />
              <label htmlFor="sore_throat">Sore Throat</label>
              <br />
              <Checkbox
                type="checkbox"
                id="difficulty_swallowing"
                name="symptoms"
                value="difficulty swallowing"
              />
              <label htmlFor="difficulty_swallowing">
                Difficulty Swallowing
              </label>
              <br />
              <Checkbox
                type="checkbox"
                id="nose"
                name="symptoms"
                value="fever"
              />
              <label htmlFor="nose">Runny or stuffy/congested nose</label>
              <br />
              <Checkbox
                type="checkbox"
                id="pinkeye"
                name="symptoms"
                value="pink eye"
              />
              <label htmlFor="pinkeye">Pink Eye</label>
              <br />
              <Checkbox
                type="checkbox"
                id="none"
                name="symptoms"
                value="none"
              />
              <label htmlFor="none">None of the Above</label>
              <br />
            </div>
            {/* Vaccinated? */}
            <div className="form__row">
              <div>
                <label htmlFor="vaccine" className="form__row--header">
                  Are you vaccinated?
                </label>
              </div>
              <RadioGroup className="form__col">
                <div>
                  <Radio type="radio" name="vaccine" value="1" required /> 
                  <label htmlFor="1"> Received 1st shot</label>
                  <br />
                </div>
                <div>
                  <Radio type="radio" name="vaccine" value="2" required />  
                  <label htmlFor="2">Received 2nd shot</label>
                </div>
                <div>
                  <Radio type="radio" name="vaccine" value="0" required />  
                  <label htmlFor="0">Not vaccinated</label>
                </div>
              </RadioGroup>
            </div>
            {/* Awaiting? */}
            <div className="form__row">
              <div>
                <label htmlFor="age" className="form__row--header">
                  Is anyone you live with currently experiencing any new
                  COVID-19 symptoms and/or waiting for test results after
                  experiencing symptoms?
                </label>
              </div>
              <RadioGroup className="form__col">
                <div>
                  <Radio type="radio" name="test" value="yes" required /> 
                  <label htmlFor="yes">Yes</label>
                  <br />
                </div>
                <div>
                  <Radio type="radio" name="test" value="no" required />  
                  <label htmlFor="no">No</label>
                </div>
              </RadioGroup>
            </div>
            {/* Self-Isolating? */}
            <div className="form__row">
              <div>
                <label htmlFor="isolating" className="form__row--header">
                  Has a doctor, health care provider, or public health unit told
                  you that you should be self-isolating (staying at home) today?
                </label>
              </div>
              <RadioGroup className="form__col">
                <div>
                  <Radio type="radio" name="isolating" value="yes" required /> 
                  <label htmlFor="yes">Yes</label>
                  <br />
                </div>
                <div>
                  <Radio type="radio" name="isolating" value="no" required />  
                  <label htmlFor="no">No</label>
                </div>
              </RadioGroup>
            </div>
            {/* Travelled? */}
            <div className="form__row">
              <div>
                <label htmlFor="travelled" className="form__row--header">
                  In the last 14 days, have you travelled outside of Canada?
                </label>
              </div>
              <RadioGroup className="form__col">
                <div>
                  <Radio type="radio" name="travelled" value="yes" required /> 
                  <label htmlFor="yes">Yes</label>
                  <br />
                </div>
                <div>
                  <Radio type="radio" name="travelled" value="no" required />  
                  <label htmlFor="no">No</label>
                </div>
              </RadioGroup>
            </div>
            {/* Close Contact? */}
            <div className="form__row">
              <div>
                <label htmlFor="closecontact" className="form__row--header">
                  In the last 14 days, have you been identified as a “close
                  contact” of someone who currently has COVID-19?ĸ
                </label>
              </div>
              <RadioGroup className="form__col">
                <div>
                  <Radio
                    type="radio"
                    name="closecontact"
                    value="yes"
                    required
                  />
                   <label htmlFor="yes">Yes</label>
                  <br />
                </div>
                <div>
                  <Radio type="radio" name="closecontact" value="no" required />{" "}
                   <label htmlFor="no">No</label>
                </div>
              </RadioGroup>
            </div>
          </div>
          {/* Signature Card */}
          <div className="card">
            {/* Signature */}
            <div className="form__row">
              <div>
                <label htmlFor="signature" className="form__row--header">
                  Please Sign Below:
                </label>
              </div>
              <div className="form__col">
                <SignatureCanvas
                  ref={inputRef}
                  canvasProps={{
                    className: "sigCanvas",
                    height: "250px", //pretty sure that adding this affects the image created
                  }}
                />
                <Button
                  variant="contained"
                  id="clear"
                  onClick={() => inputRef.current?.clear()}
                >
                  Clear
                </Button>
              </div>
            </div>
          </div>
          {/* Submit Button*/}
          <div className="form__row">
            <input type="submit" id="submit" />
          </div>
        </form>
        <p className="copyright-text">
          © {year} Waiver Site. All rights reserved.
        </p>
        <h6>
          For more info or to get your own contactless waiver form,{" "}
          <a href="http://www.waiver.site/index.html">click here</a>.
        </h6>
      </div>
    );
  } else {
    return <></>;
  }

  function upload(e) {
    if (confirmCheckBoxes("symptoms") == false) {
      //if a symptom hasnt been selected
      e.preventDefault();
      alert("Please select a checkbox!"); // *change to text prompt*
      setTimeout(5000, upload); //try uploading again in 5 seconds
    } else if (!address) {
      e.preventDefault();
      alert("Please enter a address!"); // *change to text prompt*
      setTimeout(5000, upload); //try uploading again in 5 seconds
    } else if (inputRef.current?.isEmpty() == true) {
      //if SignatureCanvas is empty, then prompt
      e.preventDefault();
      alert("Please enter a signature!"); // *change to text prompt*
      setTimeout(5000, upload); //try uploading again in 5 seconds
    } else {
      e.preventDefault();
      var date = new Date();
      var time = date.toLocaleTimeString();
      var futureTime = date.getHours() + 6;
      futureTime = futureTime % 24;
      var future = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        futureTime,
        date.getMinutes(),
        0
      );

      data = inputRef.current?.toDataURL("image/png"); //converting signature input to a data url
      var td = String(year) + "-" + String(month + 1) + "-" + String(day); //todays date

      //Generates unique ID (not truly unique; tiny chance of duplicate if there are many users)
      referenceID =
        Date.now().toString(36) + Math.random().toString(36).substr(2, 5);

      firebase
        .firestore()
        .collection("companies_test")
        .doc(id)
        .collection("submissions")
        .add({
          Date: td,
          Time: time,
          Name: document.getElementById("name").value,
          Phone: document.getElementById("phone").value,
          Email: document.getElementById("email").value,
          Birthdate: document.getElementById("birthdate").value,
          Address: address.label,
          Symptoms: getCheckedBoxes("symptoms"),
          Vaccinated: getRadioVal("vaccine"),
          Waiting: getRadioVal("test"),
          Isolating: getRadioVal("isolating"),
          Travelled: getRadioVal("travelled"),
          CloseContact: getRadioVal("closecontact"),
          signatureUrl: data,
          referenceID: referenceID,
        })
        .catch(function (error) {
          console.error("Error submitting form!");
        });
      if (match.url.charAt(match.url.length - 1) === "/") {
        history.push(`${match.url}confirmation/`, {
          check: {
            referenceID: referenceID,
            Symptoms: getCheckedBoxes("symptoms"),
            Vaccinated: getRadioVal("vaccine"),
            Waiting: getRadioVal("test"),
            Isolating: getRadioVal("isolating"),
            Travelled: getRadioVal("travelled"),
            CloseContact: getRadioVal("closecontact"),
            Time: future.toLocaleTimeString(),
          },
          info: docInfo,
        });
      } else {
        history.push(`${match.url}/confirmation/`, {
          check: {
            referenceID: referenceID,
            Symptoms: getCheckedBoxes("symptoms"),
            Vaccinated: getRadioVal("vaccine"),
            Waiting: getRadioVal("test"),
            Isolating: getRadioVal("isolating"),
            Travelled: getRadioVal("travelled"),
            CloseContact: getRadioVal("closecontact"),
            Time: future.toLocaleTimeString(),
          },
          info: docInfo,
        });
      }

      document.getElementById("form").reset();
      inputRef.current?.clear(); //clears signature pad
    }
  }
}

// Additional Components //

//This is the confirmation page component
function Confirmation() {
  const match = useRouteMatch();
  const history = useHistory();

  // console.log(history.state);
  if (
    history.location.state.check.Symptoms[0] == "none" &&
    (history.location.state.check.Vaccinated == "1" ||
      history.location.state.check.Vaccinated == "2") &&
    history.location.state.check.Waiting == "no" &&
    history.location.state.check.Isolating == "no" &&
    history.location.state.check.Travelled == "no" &&
    history.location.state.check.CloseContact == "no"
  ) {
    return <Success id={match.params.id} info={history.location.state.info} />;
  } else {
    return <Fail id={match.params.id} info={history.location.state.info} />;
  }
}

//This is the confirmation success component
function Success(params) {
  const match = useRouteMatch();
  const history = useHistory();
  var today = new Date();

  return (
    <div className="form">
      <div className="card" id="card--confirmation">
        <h1>Thank You!</h1>
        <p>You have passed our screening and we look forward to seeing you.</p>
        <p style={{ margin: "0 12%" }}>
          Please advise our staff when you arrive that you have completed the
          online screening and we can confirm on our system.
        </p>
        <h4>
          {params.info.name}, {params.info.address}
        </h4>
        <h4>( {params.info.phone} )</h4>
        <div className="card__header">
          <img
            id="card__confirmation--img"
            src="https://media.giphy.com/media/PLGPpRRU8atUvPilot/giphy.gif"
          ></img>
        </div>
        <h2>
          Your reference ID is: {history.location.state.check.referenceID}
        </h2>
        <p style={{ fontSize: "13pt" }}>
          Your successful screening confirmation expires at{" "}
          <b>{history.location.state.check.Time}</b>
        </p>
        <Button
          variant="contained"
          onClick={() => history.push(`/form/${params.id}`, params.info)}
        >
          Return to Form
        </Button>
      </div>
      <p className="copyright-text">
        © {today.getFullYear()} Waiver Site. All rights reserved.
      </p>
      <h6>
        For more info or to get your own contactless waiver form,{" "}
        <a href="http://www.waiver.site/index.html">click here</a>.
      </h6>
    </div>
  );
}

//This is the confirmation failure component
function Fail(params) {
  const match = useRouteMatch();
  const history = useHistory();
  var today = new Date();

  return (
    <div className="form">
      <div className="card" id="card--confirmation">
        <h1>Sorry!</h1>
        <p>YOU HAVE NOT PASSED OUR SCREENING.</p>
        <p style={{ margin: "0 12%" }}>
          PLEASE DO NOT COME TO OUR LOCATION UNTIL YOUR SYMPTOMS HAVE BEEN
          RESOLVED.
        </p>
        <h4>
          {params.info.name}, {params.info.address}
        </h4>
        <h4>{params.info.phone}</h4>
        <div className="card__header">
          <img
            id="card__confirmation--img"
            src="https://media.giphy.com/media/3o72EUgNr1GswFpyfe/giphy.gif"
          ></img>
        </div>
        <h2>
          Your reference ID is: {history.location.state.check.referenceID}
        </h2>
        <Button
          variant="contained"
          onClick={() => history.push(`/form/${params.id}`, params.info)}
        >
          Return to Form
        </Button>
      </div>
      <p className="copyright-text">
        © {today.getFullYear()} Waiver Site. All rights reserved.
      </p>
      <h6>
        For more info or to get your own contactless waiver form,{" "}
        <a href="http://www.waiver.site/index.html">click here</a>.
      </h6>
    </div>
  );
}

// Helper Methods //
//  Returns the value of checked radio box
function getRadioVal(id) {
  var radios = document.getElementsByName(id);

  for (var i = 0, length = radios.length; i < length; i++) {
    if (radios[i].checked) {
      return radios[i].value;
    }
  }
}

//  Returns the values of checked boxes as an array of values
function getCheckedBoxes(id) {
  var checkboxes = document.getElementsByName(id);
  const vals = [];

  for (var i = 0, length = checkboxes.length; i < length; i++) {
    if (checkboxes[i].checked) {
      vals.push(checkboxes[i].value);
    }
  }
  return vals;
}

// Ensures that at least on check box has been checked
function confirmCheckBoxes(id) {
  var checkboxes = document.getElementsByName(id);
  var atLeastOneChecked = false;

  for (var i = 0; i < checkboxes.length; i++) {
    if (checkboxes[i].checked === true) {
      atLeastOneChecked = true;
    }
  }
  return atLeastOneChecked;
}

export { Form, Confirmation };
