// For Firebase JS SDK v7.20.0 and later, measurementId is optional

import firebase from "firebase";

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyCkEhhUY_-XgZDOlHx-U1rOdKFTylUEZtA",
    authDomain: "simple-form-2d17c.firebaseapp.com",
    projectId: "simple-form-2d17c",
    storageBucket: "simple-form-2d17c.appspot.com",
    messagingSenderId: "284998988283",
    appId: "1:284998988283:web:c9288b2cd96005f54f1b5e",
    measurementId: "G-6M2ZNKN9QL",
});

const db = firebaseApp.firestore();
const auth = firebase.auth();
const storage = firebase.storage();


export { db, storage, auth };

  // export default db;
  //