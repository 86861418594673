import "./App.css";
import { db, auth } from "./firebase";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, TextField, Modal, Box, Fade } from "@material-ui/core";
import firebase from "firebase";
import { Widget } from "@uploadcare/react-widget";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

function Register(props) {
  const history = useHistory(); //this is used to navigate between pages

  const [image, setImage] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState();
  const [username, setUsername] = useState("");
  const [address, setAddress] = useState();
  const [company, setCompany] = useState();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        //user has logged in
        setUser(authUser);
        console.log(user.uid);

        try {
          //create company document
          firebase
            .firestore()
            .collection("companies_test")
            .doc(user.uid)
            .set({
              name: username,
              phone: phone,
              address: address.label,
              logo: image,
            })
            .catch(function (error) {
              console.error("Error submitting form!");
            });
          setImage("");
          setAddress("");
          
          //set company
          db.collection("companies_test")
            .doc(user.uid)
            .get()
            .then((doc) => {
              history.push("/dashboard", {
                docID: doc.id,
                info: doc.data(),
              });
            });
        } catch {
          console.log("Not logged in");
        }
      } else {
        //user has logged out
        setUser(null);
        setCompany(null);
      }
    });

    return () => {
      // perform some cleanup actions
      unsubscribe();
    };
  }, [user, username]);

  return (
    <>
      <div className="box" />
      <div className="signup">
        <form className="app__signup" onSubmit={register}>
          <center>
            <h1>Sign Up</h1>
          </center>
          <p>
            Sign up for your free trial account now. Be up and running in 5
            minutes. No credit card required. No committment.
          </p>
          <center>
            <TextField
              id="email"
              label="Email"
              variant="outlined"
              type="email"
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              required
            />
            <TextField
              id="password"
              label="Password"
              variant="outlined"
              type="password"
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              required
            />
            <Box padding={2} />
            <TextField
              id="name"
              label="Buisness Name"
              variant="outlined"
              type="name"
              margin="normal"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              fullWidth
              required
            />
            {/* Address Field */}
            <GooglePlacesAutocomplete
              apiKey="AIzaSyAfyVneLAg4iNFH0cmQtfjyZIpCPYSC7aQ"
              selectProps={{
                address,
                onChange: setAddress,
                placeholder: "Enter Your Address... *",
                styles: {
                  input: (provided) => ({
                    ...provided,
                    padding: "12px 0",
                  }),
                },
              }}
              autocompletionRequest={{
                componentRestrictions: {
                  country: ["ca"],
                },
              }}
            />
            <TextField
              id="phone"
              label="Phone Number"
              variant="outlined"
              type="phone"
              margin="normal"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              fullWidth
              required
            />
            <Box padding={2} />
            {/* Image Upload  */}
            <Widget
              publicKey="280cb5df09a0b14989eb"
              id="file"
              tabs="file url"
              onChange={(info) => setImage(info.cdnUrl)}
            />
            <Box margin={3} />
            <button
              className="signup__button"
              type="submit"
              variant="contained"
              color="primary"
            >
              Sign Up
            </button>
            <Box margin={2} />
          </center>
          <p>
            Already have an account? <a href="login">Sign In</a>
          </p>
        </form>
      </div>
    </>
  );

  function register(e) {
    if (!image) {
      e.preventDefault();
      alert("Please upload an image!"); // *change to text prompt*
      setTimeout(5000, register); // try registering again in 5 seconds
    } else if (!address) {
      e.preventDefault();
      alert("Please add your address!"); // *change to text prompt*
      setTimeout(5000, register); // try registering again in 5 seconds
    } else {
      e.preventDefault();

      //creating a company user
      auth
        .createUserWithEmailAndPassword(email, password)
        .then((authUser) => {
          setUser(authUser.user);
          return authUser.user.updateProfile({
            displayName: username,
          });
        })
        .catch((error) => alert(error.message));
    }
  }
}

export default Register;
