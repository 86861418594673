import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import Table from "./Table";
import Login from "./Login";
import Register from "./Register";
import { Form, Confirmation } from "./Form";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <Router>
    <Switch>
      <Route
        exact
        path="/"
        render={() => {
          window.location.href = "home.html";
        }}
      />
      {/* <Route exact path="/landing" component={App} /> */}
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/dashboard" component={Table} />
      <Route exact path="/form/:id" component={Form} />
      <Route exact path="/form/:id/confirmation" component={Confirmation} />
    </Switch>
  </Router>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
