import "./App.css";
import { db, auth } from "./firebase";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, TextField, Modal, Box, Fade } from "@material-ui/core";


function Login() {
  const history = useHistory(); //this is used to navigate between pages
  const [company, setCompany] = useState([]);

  const [openSignIn, setOpenSignIn] = useState(false);
  const [username, setUsername] = useState("");
  const [user, setUser] = useState(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        //user has logged in
        setUser(authUser);

        //set company
        try {
          db.collection("companies_test")
            .doc(user.uid)
            .get()
            .then((doc) => {
              history.push("/dashboard", {
                docID: doc.id,
                info: doc.data(),
              });
            });
        } catch {
          console.log("Not logged in");
        }
      } else {
        //user has logged out
        setUser(null);
        setCompany(null);
      }
    });
    return () => {
      // perform some cleanup actions
      unsubscribe();
    };
  }, [user, username]);

  const signIn = (event) => {
    event.preventDefault();

    auth
      .signInWithEmailAndPassword(email, password)
      .catch((error) => alert(error.message));

    setOpenSignIn(false);
  };

  return (
    <>
      <div className="box" />
      <div className="signup">
        <form>
          <center>
            {/* name phone email address logo */}
            <h1>Sign In</h1>
          </center>
          <p>
            Welcome back to Waiver Site. Log in below with your email
            address and password.
          </p>
          <center>
            <TextField
              id="email"
              label="Email"
              variant="outlined"
              type="email"
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              required
            />
            <TextField
              id="password"
              label="Password"
              variant="outlined"
              type="password"
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              required
            />
            <Box margin={3} />
            <button
              className="signup__button"
              onClick={signIn}
              type="submit"
              variant="contained"
              color="primary"
            >
              Sign In
            </button>
            <Box margin={2} />
            </center>
            <p>
              Don't have an account yet? <a href="register">Create Account</a>
            </p>
        </form>
      </div>
    </>
  );
}

export default Login;
