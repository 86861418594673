import "./App.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Login from "./Login";

function App() {
  const history = useHistory(); //this is used to navigate between pages

  return <Login />;
}

export default App;
